import { render, staticRenderFns } from "./ReturnPartsListSideMenu.vue?vue&type=template&id=9081c592&scoped=true"
import script from "./ReturnPartsListSideMenu.vue?vue&type=script&lang=js"
export * from "./ReturnPartsListSideMenu.vue?vue&type=script&lang=js"
import style0 from "./ReturnPartsListSideMenu.vue?vue&type=style&index=0&id=9081c592&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9081c592",
  null
  
)

export default component.exports