import { TableColumn } from '@/classes'

const Columns = [
  new TableColumn('Return No.', 'returnNoRef', true, 'desc', 'left'),
  new TableColumn('Return Date', 'returnDate', true, 'asc', 'left'),
  new TableColumn('Vendor', 'vendorName', true, 'desc', 'left'),
  new TableColumn('Order No.', 'purchaseOrderNoRef', true, 'desc', 'left'),
  new TableColumn('Quote No.', 'quoteNoRef', true, 'desc', 'left'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

const ReturnPartsDetail = [
  new TableColumn('PO No.', 'poNumber', false, 'desc', 'left'),
  new TableColumn('Inv No.', 'invoiceNo', false, 'asc', 'left'),
  new TableColumn('Inv Date', 'recDate', false, 'desc', 'left'),
  new TableColumn('Model', 'model', false, 'desc', 'left'),
  new TableColumn('Recd', 'rced', false, 'desc', 'left'),
  new TableColumn('Retd', 'return', false, 'desc', 'left'),
  new TableColumn('Returning', 'returning', false, 'desc', 'left'),
  new TableColumn('Incl. GST', 'totalAmount', false, 'desc', 'left'),
  new TableColumn('Part No.', 'partNo', false, 'desc', 'left'),
  new TableColumn('Part Desc', 'script', false, 'desc', 'left'),
  new TableColumn('Quote No', 'quoteNo', false, 'desc', 'left')
  // new TableColumn('', 'action', false, 'asc', 'centered')
]

const ReturnPartsItems = [
  new TableColumn('PO No.', 'poNumber', false, 'desc', 'left'),
  new TableColumn('Inv No.', 'invoiceNo', false, 'asc', 'left'),
  new TableColumn('Inv Date', 'recDate', false, 'desc', 'left'),
  new TableColumn('Model', 'model', false, 'desc', 'left'),
  new TableColumn('Recd', 'rced', false, 'desc', 'left'),
  new TableColumn('Retd', 'return', false, 'desc', 'left'),
  new TableColumn('Returning', 'returning', false, 'desc', 'left'),
  new TableColumn('Incl. GST', 'totalAmount', false, 'desc', 'left'),
  new TableColumn('Part No.', 'partNo', false, 'desc', 'left'),
  new TableColumn('Part Desc', 'script', false, 'desc', 'left'),
  new TableColumn('Quote No', 'quoteNo', false, 'desc', 'left'),
  new TableColumn('', 'action', false, 'asc', 'centered')
]

const RemarkColumns = [
  new TableColumn('Created', 'remarkDateTime', false, 'desc', 'left'),
  new TableColumn('Remark', 'remarks', false, 'desc', 'left'),
  new TableColumn('', 'action', false, 'asc', 'centered')
]

export { Columns, ReturnPartsDetail, RemarkColumns, ReturnPartsItems }
